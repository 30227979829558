<template>
  <b-card>
    <header-table title="لجان مجتمعية" @create="onAdd" />
    <div class="border pt-1 px-1 rounded-lg mb-1 shadow-sm">
      <b-row>
        <b-col cols="3">
          <form-input v-model="filter" placeholder=" ابحث هنا عن اسم اللجنة..." />
        </b-col>
      </b-row>
    </div>
    <data-table ref="estatesTableee" :fields="fields" :filter="filter" ep="/committees">
      <template #cell(actions)="row">
        <div class="d-flex justify-content-end ">
          <actions-table @delete="onDelete(row.item)" @update="onUpdateClicked(row.item)" />
        </div>
      </template>
    </data-table>
    <form-modal ref="estateModalAboutContent" :formSchema="estatesFormSchema" title="إضافة" v-model="activModel"
      :loading="loading" @confirm="onModalConfirmed">
    </form-modal>
  </b-card>
</template>
  
<script>
import { BCard, BButton, BCollapse, BRow, BCol } from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import DataTable from "@/components/data-table/index.vue"
import FormModal from "@/components/FormModal.vue"
import HeaderTable from "@/components/data-table/components/header-table.vue"
import ActionsTable from "@/components/data-table/components/actions-table.vue"
import FormInput from "@/components/form-input/index.vue"

export default {
  components: { BRow, BCol, BCard, BButton, BCollapse, DataTable, FormModal, HeaderTable, ActionsTable, FormInput },
  data() {
    return {
      activModel: false,
      filter: "",
      fields: [
        { key: "name", label: "اسم اللجنة" },
        { key: "committee_boss_name", label: "اسم رئيس اللجنة" },
        { key: "phone_number", label: "الرقم" },
        { key: "actions", label: "" }
      ],

      estatesFormSchema: {
        name: {
          component: "FormInput",
          key: "name",
          attrs: {
            id: "name",
            label: "اسم اللجنة",
            rules: "required",
          },
        },
        committee_boss_name: {
          component: "FormInput",
          key: "committee_boss_name",
          attrs: {
            id: "committee_boss_name",
            label: "اسم رئيس اللجنة",
            rules: "required",
          },
        },
        phone_number: {
          component: "FormMask",
          key: "phone_number",
          attrs: {
            options: "phone",
            rules: "required",
            label: "رقم الموبايل",
            dir: "ltr",
          },
        },
      }
    };
  },
  methods: {
    ...mapActions("manageCenter/communityCommittees", ["update", "delete", "create"]),
    onAdd() {
      this.$refs.estateModalAboutContent.init({});
      this.activModel = true;
    },
    onDelete(item) {
      this.$bvModal
        .msgBoxConfirm("هل أنت متأكد من حذف", {
          title: "تأكيد حذف",
          size: "sm",
          okVariant: "danger",
          okTitle: "تأكيد",
          cancelTitle: "إلغاء",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value)
            this.delete({ id: item.id }).then(() => {
              this.$refs.estatesTableee.refreshTable();
            });
        });
    },
    onUpdateClicked(form) {
      this.$refs.estateModalAboutContent.init(form);
      this.activModel = true;
    },
    onModalConfirmed(form) {
      if (form.id) {
        this.update({ data: form, id: form.id }).then(() => {
          this.$refs.estatesTableee.refreshTable();
          this.activModel = false;
        });
      } else
        this.create({ data: form }).then(() => {
          this.$refs.estatesTableee.refreshTable();
          this.activModel = false;
        });
    },

  },

  computed: {
    ...mapGetters("manageCenter/communityCommittees", ["loading"]),
  },
};
</script>